import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import PhoneIcon from '@mui/icons-material/Phone';
import Box from '@mui/material/Box';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Button from '@mui/material/Button';
import axios from 'axios';
import Swal from 'sweetalert2';

// Helper function to get today's date in the desired format
const getFormattedDate = () => {
  const today = new Date();
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return today.toLocaleDateString('en-US', options);
};

const CustomAppBar = () => {
  const currentDate = getFormattedDate(); // Get today's formatted date

  // Function to handle attendance updates
  const handleAttendanceUpdate = () => {
    // Check if the current time is after 12 PM (noon)
    const now = new Date();
    const hour = now.getHours();

    if (hour <= 10) {
      Swal.fire({
        icon: 'error',
        title: 'تنبيه',
        text: "تحديث الحضور يجب ان يكون بعد ال 10 صباحا",
        confirmButtonText: 'حسناً',
      });
      return;
    }

    // Proceed with attendance update if the time is before 12 PM
    axios
      .post('https://backedndforelbatt.elbatt.com/update_absence.php')
      .then((response) => {
        if (response.data.success) {
          const absentEmployees = response.data.absentEmployees.join(', ');
          Swal.fire({
            icon: 'success',
            title: 'تم تحديث الحضور بنجاح',
            text: `تم اعتبار الموظفين التالين غائبين: ${absentEmployees}`,
            confirmButtonText: 'حسناً',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: 'فشل تحديث الحضور: ' + response.data.message,
            confirmButtonText: 'حسناً',
          });
        }
      })
      .catch((error) => {
        console.error('Error updating attendance:', error);
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: 'حدث خطأ أثناء الاتصال بالسيرفر.',
          confirmButtonText: 'حسناً',
        });
      });
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: '#2C2C2C' }}>
      <Toolbar>
        {/* Dynamic Date */}
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
          <Typography
            sx={{ backgroundColor: '#1976D2', padding: '4px 10px', borderRadius: '20px', color: 'white', mr: 1 }}
          >
            {currentDate} {/* Display the current date dynamically */}
          </Typography>
        </Box>

        {/* Telephone Calls */}
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
          <IconButton color="inherit">
            <PhoneIcon style={{ color: 'orange' }} />
          </IconButton>
          <Typography sx={{ color: 'white', fontWeight: 'bold' }}>Telephone</Typography>
          <Typography sx={{ color: 'white', ml: 1 }}>240 Calls</Typography>
        </Box>

        {/* Search Bar */}
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              backgroundColor: '#444',
              padding: '8px 20px',
              borderRadius: '30px',
              display: 'flex',
              alignItems: 'center',
              width: '400px',
            }}
          >
            <SearchIcon style={{ color: 'white', marginRight: '10px' }} />
            <Typography sx={{ color: 'white' }}>Search</Typography>
          </Box>
        </Box>

        {/* Update Attendance Button */}
        <Button
          variant="contained"
          color="primary"
          sx={{ mr: 2, backgroundColor: '#6fd943', fontFamily: 'Cairo, sans-serif' }}
          onClick={handleAttendanceUpdate}
        >
          تحديث الحضور
        </Button>

        {/* Icons and User Profile */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* Notification Icon with Badge */}
          <Badge color="error" variant="dot" sx={{ mr: 2 }}>
            <NotificationsIcon style={{ color: 'white' }} />
          </Badge>

          {/* Settings Icon */}
          <IconButton color="inherit" sx={{ mr: 2 }}>
            <SettingsIcon style={{ color: 'white' }} />
          </IconButton>

          {/* User Avatar */}
          <Avatar alt="User Profile" src="/path/to/profile-pic.jpg" />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
