import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Grid, Paper, Typography, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@mui/material';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import axios from 'axios';
import 'react-big-calendar/lib/css/react-big-calendar.css';
const AddPost = lazy(() => import('../../Components/AddPost/AddPost'));

const Sidebar = lazy(() => import('../../Components/SideBar/SideBar'));
const Employees = lazy(() => import('../../Components/Employees/Employees'));
const EmployeeTable = lazy(() => import('../../Components/EmployeeTable/EmployeeTable'));
const EmployeeView = lazy(() => import('../../Components/EmployeeView/EmployeeView'));
const Departments = lazy(() => import('../../Components/Departments/Departments'));
const ProblemsWithUsers = lazy(() => import('../../Components/ProblemsWithUsers/ProblemsWithUsers'));
const RequestsAndLeaves = lazy(() => import('../../Components/RequestsAndLeaves/RequestsAndLeaves'));
const MeetingTimes = lazy(() => import('../../Components/MeetingTimes/MeetingTimes'));
const TicketsForAdmin = lazy(() => import('../../Components/TicketsForAdmin/TicketsForAdmin'));
const OfficialHolidays = lazy(() => import('../../Components/OfficialHolidays/OfficialHolidays'));
const EmployeeLeaveStatus = lazy(() => import('../../Components/EmployeeLeaveStatus/EmployeeLeaveStatus'));
const StatisticsAndApplications = lazy(() => import('../../Components/StatisticsAndApplications/StatisticsAndApplications'));
const DashboardHrLinear = lazy(() => import('../../Components/DashboardHrLinear/DashboardHrLinear'));
const AddAdvertisement = lazy(() => import('../../Pages/Ads/Ads'));

const localizer = momentLocalizer(moment);

const HrDashboard = () => {
  const [events, setEvents] = useState([]);
  const [open, setOpen] = useState(false);
  const [newEvent, setNewEvent] = useState({ title: '', start: new Date(), end: new Date() });
  const [selectedComponent, setSelectedComponent] = useState('Dashboard');
  const [problems, setProblems] = useState([]);
  const [users, setUsers] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [employeeCount, setEmployeeCount] = useState(0); 
  const [loading, setLoading] = useState(true);
  const [seenProblems, setSeenProblems] = useState(() => {
    const saved = localStorage.getItem('seenProblems');
    return saved ? JSON.parse(saved) : [];
  });

  useEffect(() => {
    const fetchProblemsAndUsers = async () => {
      try {
        const problemsResponse = await axios.get('https://backedndforelbatt.elbatt.com/getProblems.php');
        const usersResponse = await axios.get('https://backedndforelbatt.elbatt.com/getemployees.php');
        const ticketsResponse = await axios.get('https://backedndforelbatt.elbatt.com/getAllTicketsForAllUsers.php');
  
        setUsers(usersResponse.data.data);
  
        if (problemsResponse.data.success && usersResponse.data.status === 'success' && ticketsResponse.data.success) {
          setProblems(problemsResponse.data.data);
          setTickets(ticketsResponse.data.data);
        } else {
          setProblems([]);
          setUsers([]);
          setTickets([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setProblems([]);
        setUsers([]);
        setTickets([]);
      } finally {
        setLoading(false);
      }
    };
  
    fetchProblemsAndUsers();
  }, []);
  

  useEffect(() => {
    const fetchMeetingTimes = async () => {
      try {
        const response = await axios.get('https://backedndforelbatt.elbatt.com/fetch_meeting_times.php?room=room1');
        setEvents(response.data.map(meeting => ({
          title: meeting.title,
          start: new Date(meeting.start),
          end: new Date(meeting.end)
        })));
      } catch (error) {
        console.error('Error fetching meeting times:', error);
      }
    };

    fetchMeetingTimes();
  }, []);

  useEffect(() => {
    const fetchEmployeeCount = async () => {
      try {
        const response = await axios.get('https://backedndforelbatt.elbatt.com/getemployees.php');
        if (response.data.status === 'success') {
          setEmployeeCount(response.data.data.length);
        } else {
          setEmployeeCount(0);
        }
      } catch (error) {
        console.error('Error fetching employee count:', error);
        setEmployeeCount(0);
      }
    };

    fetchEmployeeCount();
  }, []);


  
  const handleSelectSlot = ({ start }) => {
    setNewEvent({ ...newEvent, start, end: start });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddEvent = () => {
    setEvents([...events, newEvent]);
    setOpen(false);
  };

  const handleMarkAsSeen = (problemId) => {
    const updatedSeenProblems = [...seenProblems, problemId];
    setSeenProblems(updatedSeenProblems);
    localStorage.setItem('seenProblems', JSON.stringify(updatedSeenProblems));
  };

  const unseenProblemsCount = problems.filter(problem => !seenProblems.includes(problem.problem_id)).length;

  const renderComponent = () => {
    switch (selectedComponent) {
      case 'Dashboard':
        return (
          <>
            <Typography variant="h4" style={{ fontFamily: 'Cairo, sans-serif', textAlign: "right", margin: '16px' }}>
              استعراض بياني
            </Typography>
            <Suspense fallback={<CircularProgress />}>
              <DashboardHrLinear/>
            </Suspense>
            <Grid container spacing={3} style={{ direction: "rtl" }}>
              <Grid item xs={12} md={8}>
                <Typography variant="h5" style={{ fontFamily: 'Cairo, sans-serif', textAlign: "right", margin: '16px' }}>
                  التقويم الشهري
                </Typography>
                <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
                  <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500, cursor: "pointer" }}
                    selectable
                    onSelectSlot={handleSelectSlot}
                    dayPropGetter={(date) => ({
                      style: {
                        backgroundColor: moment(date).isSame(new Date(), 'day') ? '#6fd943' : null,
                      },
                    })}
                    rtl
                  />
                </Paper>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography variant="h5" style={{ fontFamily: 'Cairo, sans-serif', textAlign: "right", margin: '16px' }}>
                  الجدول الزمني للاجتماع
                </Typography>
                <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif' }}>العنوان</TableCell>
                          <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif' }}>تاريخ البدء</TableCell>
                          <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif' }}>وقت البدء</TableCell>
                          <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif' }}>تاريخ الانتهاء</TableCell>
                          <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif' }}>وقت الانتهاء</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {events.map((event, index) => (
                          <TableRow key={index}>
                            <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif' }}>{event.title}</TableCell>
                            <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif' }}>{moment(event.start).format('LL')}</TableCell>
                            <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif' }}>{moment(event.start).format('LT')}</TableCell>
                            <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif' }}>{moment(event.end).format('LL')}</TableCell>
                            <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif' }}>{moment(event.end).format('LT')}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>إضافة حدث جديد</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  يرجى ملء التفاصيل لإضافة حدث جديد إلى التقويم.
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  label="العنوان"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={newEvent.title}
                  onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
                />
                <TextField
                  margin="dense"
                  label="التاريخ"
                  type="datetime-local"
                  fullWidth
                  variant="standard"
                  value={moment(newEvent.start).format('YYYY-MM-DDTHH:mm')}
                  onChange={(e) => setNewEvent({ ...newEvent, start: new Date(e.target.value), end: new Date(e.target.value) })}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>إلغاء</Button>
                <Button onClick={handleAddEvent}>إضافة</Button>
              </DialogActions>
            </Dialog>
          </>
        );
      case 'EmployeeTable':
        return (
          <Suspense fallback={<CircularProgress />}>
            <EmployeeTable />
          </Suspense>
        );
      case 'EmployeeView':
        return (
          <Suspense fallback={<CircularProgress />}>
            <EmployeeView />
          </Suspense>
        );
      case 'Departments':
        return (
          <Suspense fallback={<CircularProgress />}>
            <Departments />
          </Suspense>
        );
      case 'Complaints':
        return (
          <Suspense fallback={<CircularProgress />}>
            <ProblemsWithUsers problems={problems} users={users} onMarkAsSeen={handleMarkAsSeen} />
          </Suspense>
        );
      case 'RequestsAndLeaves':
        return (
          <Suspense fallback={<CircularProgress />}>
            <RequestsAndLeaves />
          </Suspense>
        );
        case 'AddPost':
  return (
    <Suspense fallback={<CircularProgress />}>
      <AddPost />
    </Suspense>
  );
      case 'MeetingTimes':
        return (
          <Suspense fallback={<CircularProgress />}>
            <MeetingTimes />
          </Suspense>
        );
      case 'OfficialHolidays':
        return (
          <Suspense fallback={<CircularProgress />}>
            <OfficialHolidays />
          </Suspense>
        );
      case 'StatisticsAndApplications':
        return (
          <Suspense fallback={<CircularProgress />}>
            <StatisticsAndApplications />
          </Suspense>
        );
      case 'TicketsForAdmin':
        return (
          <Suspense fallback={<CircularProgress />}>
            <TicketsForAdmin />
          </Suspense>
        );
      case 'EmployeeLeaveStatus':
        return (
          <Suspense fallback={<CircularProgress />}>
            <EmployeeLeaveStatus />
          </Suspense>
        );
        case 'AddAdvertisement':
          return (
            <Suspense fallback={<CircularProgress />}>
              <AddAdvertisement />
            </Suspense>
          );
      default:
        return (
          <Suspense fallback={<CircularProgress />}>
            <Employees />
          </Suspense>
        );
    }
  };

  return (
    <Suspense fallback={<CircularProgress />}>
      <Sidebar selectedComponent={selectedComponent} setSelectedComponent={setSelectedComponent} problems={problems} unseenProblemsCount={unseenProblemsCount}>
        {renderComponent()}
      </Sidebar>
    </Suspense>
  );
};

export default HrDashboard;
