import React, { useEffect, useState, lazy, Suspense } from "react";
import { CircularProgress } from "@mui/material";
import Sidebar from "../../Components/SideBar/SideBar";
import CustomAppBar from "../../Components/AppBar";
import axios from "axios";
// Lazy load components as in HrDashboard
const Charts = lazy(() => import("../../Components/Charts"));
const ProfessionalCardGrid = lazy(() => import("../../Components/CardsComponent"));
const EmployeeTable = lazy(() => import("../../Components/EmployeeTable/EmployeeTable"));
const EmployeeView = lazy(() => import("../../Components/EmployeeView/EmployeeView"));
const Departments = lazy(() => import("../../Components/Departments/Departments"));
const StatisticsAndApplications = lazy(() => import("../../Components/StatisticsAndApplications/StatisticsAndApplications"));
const TicketsForAdmin = lazy(() => import("../../Components/TicketsForAdmin/TicketsForAdmin"));
const EmployeeLeaveStatus = lazy(() => import("../../Components/EmployeeLeaveStatus/EmployeeLeaveStatus"));
const AddAdvertisement = lazy(() => import("../Ads/Ads"));
const AddPost = lazy(() => import("../../Components/AddPost/AddPost"));
const MeetingTimes = lazy(() => import("../../Components/MeetingTimes/MeetingTimes"));
const RequestsAndLeaves = lazy(() => import("../../Components/RequestsAndLeaves/RequestsAndLeaves"));
const OfficialHolidays = lazy(() => import("../../Components/OfficialHolidays/OfficialHolidays"));
const ProblemsWithUsers = lazy(() => import("../../Components/ProblemsWithUsers/ProblemsWithUsers"));

// Main dashboard component
const HomeTest = () => {
  const [selectedComponent, setSelectedComponent] = useState("Dashboard");
  const unseenProblemsCount = 3; // Example: dynamically fetch this number
  const [problems, setProblems] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Ensure that the body and html are reset to avoid external margins
    document.body.style.margin = "0";
    document.documentElement.style.margin = "0";
    document.body.style.height = "100%";
    document.documentElement.style.height = "100%";

    // Fetch problems and users (you can replace this with your actual data-fetching logic)
    const fetchProblemsAndUsers = async () => {
      try {
        const problemsResponse = await axios.get('https://backedndforelbatt.elbatt.com/getProblems.php');
        const usersResponse = await axios.get('https://backedndforelbatt.elbatt.com/getemployees.php');
        if (problemsResponse.data.success && usersResponse.data.status === 'success') {
          setProblems(problemsResponse.data.data);
          setUsers(usersResponse.data.data);
        }
      } catch (error) {
        console.error('Error fetching problems or users:', error);
      }
    };

    fetchProblemsAndUsers();
  }, []);

  // Function to render the selected component
  const renderComponent = () => {
    switch (selectedComponent) {
      case "Dashboard":
        return (
          <Suspense fallback={<CircularProgress />}>
            <Charts />
          </Suspense>
        );
      case "EmployeeTable":
        return (
          <Suspense fallback={<CircularProgress />}>
            <EmployeeTable />
          </Suspense>
        );
      case "EmployeeView":
        return (
          <Suspense fallback={<CircularProgress />}>
            <EmployeeView />
          </Suspense>
        );
      case "Departments":
        return (
          <Suspense fallback={<CircularProgress />}>
            <Departments />
          </Suspense>
        );
      case "Complaints":
        return (
          <Suspense fallback={<CircularProgress />}>
            <ProblemsWithUsers problems={problems} users={users} />
          </Suspense>
        );
      case "RequestsAndLeaves":
        return (
          <Suspense fallback={<CircularProgress />}>
            <RequestsAndLeaves />
          </Suspense>
        );
      case "AddPost":
        return (
          <Suspense fallback={<CircularProgress />}>
            <AddPost />
          </Suspense>
        );
      case "MeetingTimes":
        return (
          <Suspense fallback={<CircularProgress />}>
            <MeetingTimes />
          </Suspense>
        );
      case "OfficialHolidays":
        return (
          <Suspense fallback={<CircularProgress />}>
            <OfficialHolidays />
          </Suspense>
        );
      case "StatisticsAndApplications":
        return (
          <Suspense fallback={<CircularProgress />}>
            <StatisticsAndApplications />
          </Suspense>
        );
      case "TicketsForAdmin":
        return (
          <Suspense fallback={<CircularProgress />}>
            <TicketsForAdmin />
          </Suspense>
        );
      case "EmployeeLeaveStatus":
        return (
          <Suspense fallback={<CircularProgress />}>
            <EmployeeLeaveStatus />
          </Suspense>
        );
      case "AddAdvertisement":
        return (
          <Suspense fallback={<CircularProgress />}>
            <AddAdvertisement />
          </Suspense>
        );
      default:
        return (
          <Suspense fallback={<CircularProgress />}>
            <Charts />
          </Suspense>
        );
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#141718",
        minHeight: "100vh", // Use height to make sure it takes full viewport height
        margin: "0",
        padding: "0",
        display: "flex",
        flexDirection: "row-reverse", // Sidebar on the right
      }}
    >
      {/* Sidebar Component */}
      <Suspense fallback={<CircularProgress />}>
        <Sidebar
          selectedComponent={selectedComponent}
          setSelectedComponent={setSelectedComponent}
          unseenProblemsCount={unseenProblemsCount}
        />
      </Suspense>

      {/* Main Content */}
      <div
        style={{
          flex: 1, // Take the remaining space
          padding: "20px",
          color: "#fff",
          textAlign: "center",
        }}
      >
        <CustomAppBar />
        {renderComponent()} {/* Render the selected component */}
      </div>
    </div>
  );
};

export default HomeTest;
