import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse, CssBaseline, Badge } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TableChartIcon from '@mui/icons-material/TableChart';
import ViewListIcon from '@mui/icons-material/ViewList';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import EventNoteIcon from '@mui/icons-material/EventNote';
import LeaveStatusIcon from '@mui/icons-material/AssignmentInd';
import PostAddIcon from '@mui/icons-material/PostAdd'; // Import the icon for "Add Post"
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import "./SideBar.css";
import image from "../../images/logooo.png";

const drawerWidth = 240;

const Root = styled('div')({
  display: 'flex',
  height: '100vh',
  backgroundColor: '#232627',
});

const DrawerStyled = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  backgroundColor: "black",
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    textAlign: 'right',
    fontFamily: 'Cairo, sans-serif',
    backgroundColor: '#232627',
    border: 'none',
    color: 'white', // Ensure all drawer content is white
  },
}));

const Content = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'Cairo, sans-serif',
  backgroundColor: '#232627',
}));

const LogoContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px 0',
  borderBottom: 'none',
});

const MainContent = styled('main')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: '#232627',
  fontFamily: 'Cairo, sans-serif',
}));

const Sidebar = ({ children, selectedComponent, setSelectedComponent, unseenProblemsCount }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleNavigation = (component) => {
    setSelectedComponent(component);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Root>
      <CssBaseline />
      <Content>
        <MainContent>
          {children}
        </MainContent>
      </Content>
      <DrawerStyled
        variant="permanent"
        anchor="right"
        
      >
        <LogoContainer style={{backgroundColor:"#232627"}}>
          <img src={image} alt="Logo" style={{ width:"150px",backgroundColor:"#232627" }} />
        </LogoContainer>
        <List style={{ width: "100%", height: "100%", backgroundColor: "#232627" }}>
          <ListItem
            button
            onClick={() => handleNavigation('Dashboard')}
            selected={selectedComponent === 'Dashboard'}
            style={{
              borderRadius: '8px',
              backgroundColor: selectedComponent === 'Dashboard' ? '#6fd943' : 'transparent',
              marginBottom: '8px',
              color: "white"
            }}
          >
            <ListItemIcon style={{ color: "white" }}><HomeIcon /></ListItemIcon>
            <ListItemText primary="الرئيسية" style={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif', margin: 0, color: "white" }} />
          </ListItem>

          <ListItem button onClick={handleClick} style={{ color: "white" }}>
            <ListItemIcon style={{ color: "white" }}>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="الموظفون" style={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif', color: "white" }} />
            {open ? <ExpandLess style={{ color: "white" }} /> : <ExpandMore style={{ color: "white" }} />}
          </ListItem>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => handleNavigation('EmployeeTable')}
                selected={selectedComponent === 'EmployeeTable'}
                style={{
                  borderRadius: '8px',
                  backgroundColor: selectedComponent === 'EmployeeTable' ? '#6fd943' : 'transparent',
                  marginBottom: '8px',
                  paddingLeft: 32,
                  color: "white"
                }}
              >
                <ListItemIcon style={{ color: "white" }}><TableChartIcon /></ListItemIcon>
                <ListItemText primary="جدول الموظفين" style={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif', margin: 0, color: "white" }} />
              </ListItem>

              <ListItem
                button
                onClick={() => handleNavigation('EmployeeView')}
                selected={selectedComponent === 'EmployeeView'}
                style={{
                  borderRadius: '8px',
                  backgroundColor: selectedComponent === 'EmployeeView' ? '#6fd943' : 'transparent',
                  marginBottom: '8px',
                  paddingLeft: 32,
                  color: "white"
                }}
              >
                <ListItemIcon style={{ color: "white" }}><ViewListIcon /></ListItemIcon>
                <ListItemText primary="عرض الموظفين" style={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif', margin: 0, color: "white" }} />
              </ListItem>

              <ListItem
                button
                onClick={() => handleNavigation('Departments')}
                selected={selectedComponent === 'Departments'}
                style={{
                  borderRadius: '8px',
                  backgroundColor: selectedComponent === 'Departments' ? '#6fd943' : 'transparent',
                  marginBottom: '8px',
                  paddingLeft: 32,
                  color: "white"
                }}
              >
                <ListItemIcon style={{ color: "white" }}><BusinessIcon /></ListItemIcon>
                <ListItemText primary="الأقسام" style={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif', margin: 0, color: "white" }} />
              </ListItem>

              <ListItem
                button
                onClick={() => handleNavigation('Complaints')}
                selected={selectedComponent === 'Complaints'}
                style={{
                  borderRadius: '8px',
                  backgroundColor: selectedComponent === 'Complaints' ? '#6fd943' : 'transparent',
                  marginBottom: '8px',
                  paddingLeft: 32,
                  color: "white"
                }}
              >
                <ListItemIcon style={{ color: "white" }}>
                  <Badge badgeContent={unseenProblemsCount} color="error">
                    <NotificationsIcon />
                  </Badge>
                </ListItemIcon>
                <ListItemText primary="الشكاوي" style={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif', margin: 0, color: "white" }} />
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            button
            onClick={() => handleNavigation('RequestsAndLeaves')}
            selected={selectedComponent === 'RequestsAndLeaves'}
            style={{
              borderRadius: '8px',
              backgroundColor: selectedComponent === 'RequestsAndLeaves' ? '#6fd943' : 'transparent',
              marginBottom: '8px',
              color: "white"
            }}
          >
            <ListItemIcon style={{ color: "white" }}><AccountBalanceWalletIcon /></ListItemIcon>
            <ListItemText primary="طلبات الاجازات والاذونات" style={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif', margin: 0, color: "white" }} />
          </ListItem>

          <ListItem
            button
            onClick={() => handleNavigation('EmployeeLeaveStatus')}
            selected={selectedComponent === 'EmployeeLeaveStatus'}
            style={{
              borderRadius: '8px',
              backgroundColor: selectedComponent === 'EmployeeLeaveStatus' ? '#6fd943' : 'transparent',
              marginBottom: '8px',
              color: "white"
            }}
          >
            <ListItemIcon style={{ color: "white" }}><LeaveStatusIcon /></ListItemIcon>
            <ListItemText primary="موقف الموظفون من الاجازات والاذونات" style={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif', margin: 0, color: "white" }} />
          </ListItem>

          <ListItem
            button
            onClick={() => handleNavigation('MeetingTimes')}
            selected={selectedComponent === 'MeetingTimes'}
            style={{
              borderRadius: '8px',
              backgroundColor: selectedComponent === 'MeetingTimes' ? '#6fd943' : 'transparent',
              marginBottom: '8px',
              color: "white"
            }}
          >
            <ListItemIcon style={{ color: "white" }}><ScheduleIcon /></ListItemIcon>
            <ListItemText primary="مواعيد المقابلات والغرف" style={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif', margin: 0, color: "white" }} />
          </ListItem>

          <ListItem
            button
            onClick={() => handleNavigation('TicketsForAdmin')}
            selected={selectedComponent === 'TicketsForAdmin'}
            style={{
              borderRadius: '8px',
              backgroundColor: selectedComponent === 'TicketsForAdmin' ? '#6fd943' : 'transparent',
              marginBottom: '8px',
              color: "white"
            }}
          >
            <ListItemIcon style={{ color: "white" }}><ConfirmationNumberIcon /></ListItemIcon>
            <ListItemText primary="التذاكر" style={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif', margin: 0, color: "white" }} />
          </ListItem>

          <ListItem
            button
            onClick={() => handleNavigation('OfficialHolidays')}
            selected={selectedComponent === 'OfficialHolidays'}
            style={{
              borderRadius: '8px',
              backgroundColor: selectedComponent === 'OfficialHolidays' ? '#6fd943' : 'transparent',
              marginBottom: '8px',
              color: "white"
            }}
          >
            <ListItemIcon style={{ color: "white" }}><EventNoteIcon /></ListItemIcon>
            <ListItemText primary="الإجازات الرسمية" style={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif', margin: 0, color: "white" }} />
          </ListItem>

          <ListItem
            button
            onClick={() => handleNavigation('StatisticsAndApplications')}
            selected={selectedComponent === 'StatisticsAndApplications'}
            style={{
              borderRadius: '8px',
              backgroundColor: selectedComponent === 'StatisticsAndApplications' ? '#6fd943' : 'transparent',
              marginBottom: '8px',
              color: "white"
            }}
          >
            <ListItemIcon style={{ color: "white" }}><ViewListIcon /></ListItemIcon>
            <ListItemText primary="الإحصائيات والتطبيقات" style={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif', margin: 0, color: "white" }} />
          </ListItem>

          {/* New Add Post Item */}
          <ListItem
            button
            onClick={() => handleNavigation('AddPost')}
            selected={selectedComponent === 'AddPost'}
            style={{
              borderRadius: '8px',
              backgroundColor: selectedComponent === 'AddPost' ? '#6fd943' : 'transparent',
              marginBottom: '8px',
              color: "white"
            }}
          >
            <ListItemIcon style={{ color: "white" }}><PostAddIcon /></ListItemIcon>
            <ListItemText primary="إضافة منشور" style={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif', margin: 0, color: "white" }} />
          </ListItem>

          {/* Add Advertisement */}
          <ListItem
            button
            onClick={() => handleNavigation('AddAdvertisement')}
            selected={selectedComponent === 'AddAdvertisement'}
            style={{
              borderRadius: '8px',
              backgroundColor: selectedComponent === 'AddAdvertisement' ? '#6fd943' : 'transparent',
              marginBottom: '8px',
              color: "white"
            }}
          >
            <ListItemIcon style={{ color: "white" }}><PostAddIcon /></ListItemIcon>
            <ListItemText primary="إضافة اعلان" style={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif', margin: 0, color: "white" }} />
          </ListItem>
        </List>
      </DrawerStyled>
    </Root>
  );
};

export default Sidebar;
