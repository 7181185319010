import React, { useState } from 'react';
import {
  TextField,
  Button,
  Paper,
  Typography,
  Link,
  createTheme,
  ThemeProvider,
  CssBaseline
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// import image1 from "../../images/common (1).svg";
// import image2 from "../../images/theme-3.svg";
import logo from "../../images/logooo.png";

// Create a dark theme
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#6fd943', // Green accent
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
    text: {
      primary: '#ffffff',
      secondary: '#aaaaaa',
    },
  },
  typography: {
    fontFamily: "Cairo, sans-serif",
    fontWeight: "500",
  },
});

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleHRLogin = async () => {
    try {
      const response = await axios.post('https://backedndforelbatt.elbatt.com/HrOrNotLogin.php', {
        username,
        password,
      });
      

      console.log('Response from server:', response.data);

      if (response.data.success) {
        localStorage.setItem('authToken', response.data.token);
        navigate('/hr-dashboard');
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Error during authentication:', error);
      alert('An error occurred during login. Please try again.');
    }
  };

  const handleEmployeeLogin = async () => {
    try {
      const response = await axios.post('https://backedndforelbatt.elbatt.com/employeeLogin.php', {
        username,
        password,
      });

      console.log('Response from server:', response.data);

      if (response.data.success) {
        localStorage.setItem('authTokenEmployee', response.data.token);
        navigate(`/employee-dashboard?username=${username}`);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Error during authentication:', error);
      alert('An error occurred during login. Please try again.');
    }
  };

  const containerStyle = {
    height: '100vh',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    overflow: 'hidden',
  };

  const whiteDivStyle = {
    flex: 1,
    backgroundColor: '#121212', // Dark background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  };

  const greenDivStyle = {
    flex: 1,
    backgroundColor: '#6fd943',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  };

  const formContainerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const paperStyle = {
    padding: '40px 20px',
    width: '400px',
    textAlign: 'center',
    borderRadius: '20px',
    backgroundColor: darkTheme.palette.background.paper,
  };

  const textFieldStyle = {
    margin: '10px 0',
    '& .MuiOutlinedInput-root': {
      color: darkTheme.palette.text.primary,
    },
  };

  const buttonStyle = {
    marginTop: '20px',
    backgroundColor: darkTheme.palette.primary.main,
    color: darkTheme.palette.text.primary,
    fontFamily: 'Cairo,sans-serif',
    fontWeight: '700',
    fontSize: '18px',
  };

  const rtlContainerStyle = {
    direction: 'rtl',
    textAlign: 'right',
    width: '100%',
  };

  const linkStyle = {
    fontFamily: 'Cairo,sans-serif',
    fontWeight: '500',
    color: darkTheme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'none',
  };

  const secondaryButtonStyle = {
    marginTop: '10px',
    backgroundColor: '#5c636a',
    color: '#fff',
    fontFamily: 'Cairo,sans-serif',
    fontWeight: '700',
    fontSize: '16px',
    flex: '1',
    margin: '5px',
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  };

  const imageStyle = {
    position: 'absolute',
    bottom: 0,
    width: '50%',
    objectFit: 'cover',
  };

  const logoStyle = {
    marginBottom: '50px',
    width: '300px',
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div style={containerStyle}>
        {/* <div style={greenDivStyle}>
          <img src={image1} alt="Green Background" style={{ ...imageStyle, left: 0, transform: 'scaleX(-1)', position: 'absolute' }} />
        </div> */}
        {/* <div style={whiteDivStyle}>
          <img src={image2} alt="White Background" style={{ ...imageStyle, right: 0, transform: 'scaleX(-1)', position: 'absolute' }} />
        </div> */}
        <div style={formContainerStyle}>
          <img src={logo} alt="Logo" style={logoStyle} />
          <Paper elevation={10} style={paperStyle}>
            <div style={rtlContainerStyle}>
              <Typography style={{ fontFamily: 'Cairo,sans-serif', fontWeight: '500' }} variant="h4" component="h4" gutterBottom>
                بدء الاتصال
              </Typography>
              <Typography style={{ fontFamily: 'Cairo,sans-serif', fontWeight: '500' }} variant="body1" component="label" gutterBottom>
                اسم المستخدم
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                style={textFieldStyle}
                inputProps={{ style: { direction: 'rtl', textAlign: 'right', color: darkTheme.palette.text.primary } }}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <Typography style={{ fontFamily: 'Cairo,sans-serif', fontWeight: '500' }} variant="body1" component="label" gutterBottom>
                كلمة المرور
              </Typography>
              <TextField
                variant="outlined"
                type="password"
                fullWidth
                style={textFieldStyle}
                inputProps={{ style: { direction: 'rtl', textAlign: 'right', color: darkTheme.palette.text.primary } }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                variant="contained"
                fullWidth
                style={buttonStyle}
                onClick={handleHRLogin}
              >
                تسجيل الدخول
              </Button>
              <Typography style={{ fontFamily: 'Cairo,sans-serif', fontWeight: '500', marginTop: '20px', textAlign: 'center' }} variant="body1" component="p" gutterBottom>
                ليس لديك حساب ؟{' '}
                <Link href="/register" style={linkStyle}>
                  تسجيل
                </Link>
              </Typography>
              <div style={buttonContainerStyle}>
                <Button variant="contained" style={secondaryButtonStyle} onClick={handleHRLogin}>
                  دخول الموارد البشرية
                </Button>
                <Button variant="contained" style={secondaryButtonStyle}>
                  دخول الشركة
                </Button>
              </div>
              <div style={buttonContainerStyle}>
                <Button variant="contained" style={secondaryButtonStyle} onClick={handleEmployeeLogin}>
                  دخول الموظف
                </Button>
                <Button variant="contained" style={secondaryButtonStyle}>
                  دخول المدير العام
                </Button>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Login;
