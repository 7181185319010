import React, { useState, useEffect } from 'react';
import { Typography, Box, Paper, TextField, Button, Grid, CssBaseline, FormControl, FormLabel } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

// Create RTL cache
const cacheRtl = createCache({
  key: 'muirtl',
  prepend: true,
});

// Create RTL theme
const theme = createTheme({
  direction: 'rtl',
  typography: {
    fontFamily: 'Cairo, sans-serif',
  },
});

const PermissionRequestForm = () => {
  const [permissionData, setPermissionData] = useState({
    employeeName: '',
    department: '',
    permissionType: '',
    permissionReason: '',
    startTime: '',
    endTime: '',
  });
  const [username, setUsername] = useState('');
  const [totalPermissionHours, setTotalPermissionHours] = useState(0);
  const [pendingPermissionHours, setPendingPermissionHours] = useState(0);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const usernameParam = queryParams.get('username');
    setUsername(usernameParam);

    if (usernameParam) {
      fetchUserPermissions(usernameParam);
    }
  }, [location]);

  const fetchUserPermissions = async (username) => {
    try {
      const response = await axios.get('https://backedndforelbatt.elbatt.com/getPermissions.php');
      const permissions = response.data.data.filter(
        (permission) => permission.username === username
      );

      let acceptedHours = 0;
      let pendingHours = 0;

      permissions.forEach(permission => {
        const startTime = new Date(`1970-01-01T${permission.start_time}Z`);
        const endTime = new Date(`1970-01-01T${permission.end_time}Z`);
        const hours = (endTime - startTime) / (1000 * 60 * 60); // Calculate difference in hours

        if (permission.is_accepted === 1) {
          acceptedHours += hours;
        } else {
          pendingHours += hours;
        }
      });

      setTotalPermissionHours(acceptedHours);
      setPendingPermissionHours(pendingHours);
    } catch (error) {
      console.error('Error fetching permissions:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPermissionData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const startTime = new Date(`1970-01-01T${permissionData.startTime}Z`);
    const endTime = new Date(`1970-01-01T${permissionData.endTime}Z`);
    const requestedHours = (endTime - startTime) / (1000 * 60 * 60);

    // Check if total of accepted and pending hours exceed 5 hours
    if (totalPermissionHours + pendingPermissionHours + requestedHours > 5) {
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: `لقد طلبت بالفعل ${totalPermissionHours.toFixed(2)} ساعات وتم طلب ${pendingPermissionHours.toFixed(2)} ساعات في انتظار الموافقة. الحد الأقصى هو 5 ساعات.`,
      });
      return;
    }

    const formData = { ...permissionData, username };

    axios.post('https://backedndforelbatt.elbatt.com/postPermissions.php', formData)
      .then(response => {
        Swal.fire({
          icon: 'success',
          title: 'تم إرسال الطلب بنجاح',
          text: 'تم تقديم طلب الإذن بنجاح.',
        });
        console.log('Form submitted successfully:', response.data);
      })
      .catch(error => {
        let errorMessage = 'حدث خطأ ما. حاول مرة أخرى.';
        if (error.response) {
          errorMessage = error.response.data.message || errorMessage;
          console.error('Error response:', error.response.data);
        } else if (error.request) {
          console.error('Error request:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: errorMessage,
        });
      });
  };

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box p={2} style={{ direction: 'rtl', fontFamily: 'Cairo, sans-serif', height: '100%' }}>
          <Typography variant="h5" gutterBottom>إذن تأخير / إنصراف</Typography>
          <Paper style={{ padding: '16px', height: '100%' }}>
            <form onSubmit={handleSubmit} style={{ height: '100%' }}>
              <Grid container spacing={2} style={{ height: '100%' }}>
                <Grid item xs={12}>
                  <TextField
                    label="اسم الموظف"
                    name="employeeName"
                    fullWidth
                    value={permissionData.employeeName}
                    onChange={handleChange}
                    inputProps={{ style: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="القسم"
                    name="department"
                    fullWidth
                    value={permissionData.department}
                    onChange={handleChange}
                    inputProps={{ style: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend" style={{ textAlign: 'right' }}>نوع الإذن</FormLabel>
                    <TextField
                      name="permissionType"
                      fullWidth
                      value={permissionData.permissionType}
                      onChange={handleChange}
                      inputProps={{ style: { textAlign: 'right' } }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="سبب التأخير / الانصراف"
                    name="permissionReason"
                    fullWidth
                    value={permissionData.permissionReason}
                    onChange={handleChange}
                    inputProps={{ style: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="وقت البدء"
                    name="startTime"
                    type="time"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={permissionData.startTime}
                    onChange={handleChange}
                    inputProps={{ style: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="وقت الانتهاء"
                    name="endTime"
                    type="time"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={permissionData.endTime}
                    onChange={handleChange}
                    inputProps={{ style: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Button type="submit" variant="contained" color="primary" fullWidth>
                    إرسال
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Box>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default PermissionRequestForm;
