import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CircularProgress,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  TextField,
  IconButton as MUIIconButton,
} from "@mui/material";
import { motion } from "framer-motion";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom"; 
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import SendIcon from "@mui/icons-material/Send";
import image from "../../images/logo-color.png";

const defaultImage = "https://via.placeholder.com/150?text=No+Image";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} timeout={1000} />;
});

const CustomDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    padding: '30px',
    width: '600px',
    maxWidth: '90%',
    fontFamily: 'Cairo, sans-serif',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.5s ease',
  },
  '& .MuiDialogTitle-root': {
    fontFamily: 'Cairo, sans-serif',
    fontSize: '26px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  '& .MuiDialogContent-root': {
    fontFamily: 'Cairo, sans-serif',
    fontSize: '18px',
    textAlign: 'center',
    marginBottom: '20px',
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'center',
  },
});

const StyledCard = styled(Card)({
  maxWidth: '100%',
  margin: '10px auto',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  borderRadius: '15px',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
  },
  cursor: 'pointer',
});

const CardMediaContainer = styled(CardMedia)({
  width: '100%',
  height: '200px',
  objectFit: 'contain',
  margin: '0 auto',
  padding: '10px',
  borderRadius: '10px',
});

const CardContentContainer = styled(CardContent)({
  padding: '12px',
});

const Header = styled(AppBar)({
  backgroundColor: '#6fd943',
  fontFamily: 'Cairo, sans-serif',
  marginBottom: '20px',
  direction: 'rtl',
});

const Sidebar = styled(Drawer)({
  '& .MuiDrawer-paper': {
    width: 240,
    backgroundColor: '#fcfdfd',
    padding: '10px',
    direction: 'rtl',
  },
});

const ContentContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  direction: 'rtl',
});

const NewsCard = styled(Card)({
  marginBottom: '20px',
  cursor: 'pointer',
});

const NewsImage = styled(CardMedia)({
  height: 140,
  borderRadius: '10px 10px 0 0',
});

const isArabic = (text) => {
  const arabicCharPattern = /[\u0600-\u06FF]/;
  return arabicCharPattern.test(text);
};

const GetHomeAds = () => {
  const [ads, setAds] = useState([]);
  const [programmingNews, setProgrammingNews] = useState([]);
  const [itNews, setItNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [welcomeOpen, setWelcomeOpen] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const fetchLikesAndComments = async (adId) => {
    try {
      const likesResponse = await fetch(`https://backedndforelbatt.elbatt.com/getLikesAdds.php?ad_id=${adId}`);
      const commentsResponse = await fetch(`https://backedndforelbatt.elbatt.com/getCommentsAds.php?ad_id=${adId}`);
      const likesData = await likesResponse.json();
      const commentsData = await commentsResponse.json();

      return {
        likes: likesData.success ? likesData.likes_count : 0,
        comments: commentsData.success ? commentsData.comments : [],
      };
    } catch (error) {
      console.error("Error fetching likes and comments:", error);
      return { likes: 0, comments: [] };
    }
  };

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await fetch('https://backedndforelbatt.elbatt.com/getAdvertisements.php');
        const data = await response.json();
        if (data.success) {
          const adsWithLikesAndComments = await Promise.all(
            data.data.map(async (ad) => {
              const { likes, comments } = await fetchLikesAndComments(ad.id);
              return { ...ad, likes, comments };
            })
          );
          setAds(adsWithLikesAndComments);
        } else {
          console.error("Failed to fetch ads:", data.message);
        }
      } catch (error) {
        console.error("Error fetching ads:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchProgrammingNews = async () => {
      try {
        const response = await fetch(
          `https://newsdata.io/api/1/news?apikey=pub_52214d308a9899c94dd3aa75f11f5e39213a3&q=software&category=technology&language=en`
        );
        if (response.ok) {
          const data = await response.json();
          setProgrammingNews(data.results.filter(article => article.image_url));
        } else {
          console.error("Unexpected response status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching programming news:", error.message);
      }
    };

    const fetchItNews = async () => {
      try {
        const response = await fetch(
          `https://newsdata.io/api/1/news?apikey=pub_52214d308a9899c94dd3aa75f11f5e39213a3&q=network&category=technology&language=en`
        );
        if (response.ok) {
          const data = await response.json();
          setItNews(data.results.filter(article => article.image_url));
        } else {
          console.error("Unexpected response status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching IT news:", error.message);
      }
    };

    fetchAds();
    fetchProgrammingNews();
    fetchItNews();
  }, []);

  const handleToggleLike = async (adId) => {
    const user_email = "user@example.com"; // Replace with actual user's email
    try {
      const response = await fetch('https://backedndforelbatt.elbatt.com/likeAdds.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ad_id: adId, user_email }),
      });
      const data = await response.json();
      if (data.success) {
        setAds((prevAds) =>
          prevAds.map((ad) =>
            ad.id === adId ? { ...ad, likes: ad.likes + 1 } : ad
          )
        );
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error("Error liking ad:", error);
    }
  };

  const handleAddComment = async (adId, comment) => {
    const user_email = "user@example.com"; // Replace with actual user's email
    try {
      const response = await fetch('https://backedndforelbatt.elbatt.com/commentsAds.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ad_id: adId, user_email, comment }),
      });
      const data = await response.json();
      if (data.success) {
        setAds((prevAds) =>
          prevAds.map((ad) =>
            ad.id === adId ? { ...ad, comments: [...ad.comments, { user_email, comment }] } : ad
          )
        );
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleCloseWelcome = () => {
    setWelcomeOpen(false);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleCardClick = (link) => {
    window.open(link, '_blank');
  };

  const handleSendComment = (adId) => {
    const input = document.querySelector(`#comment-input-${adId}`);
    if (input && input.value.trim()) {
      handleAddComment(adId, input.value.trim());
      input.value = ''; // Clear the input after sending the comment
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Header style={{ marginTop: "-6px" }} position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleSidebar}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1, fontFamily: 'Cairo, sans-serif', textAlign: 'right', marginRight: "20px" }}>
            زبوما
          </Typography>
          <img style={{ maxHeight: "100px", maxWidth: "100px" }} alt="User Avatar" src={image} />
        </Toolbar>
      </Header>

      <Sidebar anchor="right" open={sidebarOpen} onClose={toggleSidebar}>
        <List>
          <ListItem button onClick={handleLoginClick}>
            <ListItemIcon><HomeIcon /></ListItemIcon>
            <ListItemText primary="دخول" sx={{ fontFamily: 'Cairo, sans-serif', textAlign: 'right' }} />
          </ListItem>
          <ListItem button>
            <ListItemIcon><InfoIcon /></ListItemIcon>
            <ListItemText primary="حول" sx={{ fontFamily: 'Cairo, sans-serif', textAlign: 'right' }} />
          </ListItem>
        </List>
      </Sidebar>

      <CustomDialog
        open={welcomeOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseWelcome}
      >
        <img src={image} alt="image" style={{ width: '100%', borderRadius: '15px', marginBottom: '15px' }} />
        <DialogTitle>مرحبا بك في زبوما</DialogTitle>
        <DialogContent>
          <Typography style={{ fontFamily: "Cairo, sans-serif" }} variant="body1">
            نحن سعداء بزيارتك. استمتع بالإعلانات المعروضة
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWelcome} variant="contained" color="primary" sx={{ fontFamily: 'Cairo, sans-serif' }}>
            إغلاق
          </Button>
        </DialogActions>
      </CustomDialog>

      <Grid container spacing={2}>
        {/* Left Sidebar for IT News */}
        <Grid item xs={3}>
          <Box sx={{ position: 'sticky', top: 20 }}>
            <Typography variant="h6" sx={{ fontFamily: 'Cairo, sans-serif', textAlign: 'center', marginBottom: 2 }}>
              أخبار تقنية المعلومات العالمية
            </Typography>
            {itNews.map((article, index) => (
              <NewsCard key={index} onClick={() => handleCardClick(article.link)}>
                <NewsImage
                  component="img"
                  image={article.image_url}
                  alt={article.title}
                />
                <CardContent>
                  <Typography variant="body1" sx={{ fontFamily: 'Cairo, sans-serif', textAlign: 'right' }}>
                    {article.title}
                  </Typography>
                  <Typography variant="caption" sx={{ fontFamily: 'Cairo, sans-serif', textAlign: 'right', color: 'gray' }}>
                    {new Date(article.pubDate).toLocaleDateString()}
                  </Typography>
                </CardContent>
              </NewsCard>
            ))}
          </Box>
        </Grid>

        {/* Main Content */}
        <Grid item xs={6}>
            <h2 style={{textAlign:"center",fontFamily:"cairo"}}>الاعلانات الخاصة بنا</h2>
          <ContentContainer>
            <Grid container spacing={2}>
              {ads.map((ad, index) => (
                <Grid item xs={12} key={ad.id}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.2 }}
                  >
                    <StyledCard>
                      {ad.image_path && (
                        <CardMediaContainer
                          component="img"
                          image={`https://backedndforelbatt.elbatt.com/${ad.image_path}`}
                          alt={ad.title}
                        />
                      )}
                      <CardContentContainer>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          sx={{
                            fontFamily: "Cairo, sans-serif",
                            textAlign: isArabic(ad.title) ? 'right' : 'left',
                            direction: isArabic(ad.title) ? 'rtl' : 'ltr',
                          }}
                        >
                          {ad.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: "Cairo, sans-serif",
                            marginBottom: 2,
                            textAlign: isArabic(ad.description) ? 'right' : 'left',
                            direction: isArabic(ad.description) ? 'rtl' : 'ltr',
                          }}
                        >
                          {ad.description}
                        </Typography>

                        {/* Like and Comment Section */}
                        <Box sx={{ marginTop: 2 }}>
                          <MUIIconButton onClick={() => handleToggleLike(ad.id)}>
                            <ThumbUpAltIcon />
                          </MUIIconButton>
                          <Typography variant="body2" sx={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '8px' }}>
                            {ad.likes} إعجاب
                          </Typography>
                        </Box>

                        <Box sx={{ marginTop: 2 }}>
                          <Typography variant="body2" sx={{ marginBottom: 1 }}>
                            التعليقات:
                          </Typography>
                          {ad.comments.map((comment, i) => (
                            <Typography key={i} variant="body2" sx={{ marginBottom: 1, paddingLeft: '16px', textAlign: 'right' }}>
                              {comment.comment} - {comment.user_email}
                            </Typography>
                          ))}

                          <Box sx={{ display: 'flex', marginTop: 1 }}>
                            <TextField
                              id={`comment-input-${ad.id}`}
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="أضف تعليقك..."
                              onKeyDown={(e) => {
                                if (e.key === 'Enter' && e.target.value.trim()) {
                                  handleSendComment(ad.id);
                                }
                              }}
                            />
                            <MUIIconButton onClick={() => handleSendComment(ad.id)}>
                              <SendIcon />
                            </MUIIconButton>
                          </Box>
                        </Box>
                      </CardContentContainer>
                    </StyledCard>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </ContentContainer>
        </Grid>

        {/* Right Sidebar for Programming News */}
        <Grid item xs={3}>
          <Box sx={{ position: 'sticky', top: 20 }}>
            <Typography variant="h6" sx={{ fontFamily: 'Cairo, sans-serif', textAlign: 'center', marginBottom: 2 }}>
              أخبار البرمجة العالمية
            </Typography>
            {programmingNews.map((article, index) => (
              <NewsCard key={index} onClick={() => handleCardClick(article.link)}>
                <NewsImage
                  component="img"
                  image={article.image_url}
                  alt={article.title}
                />
                <CardContent>
                  <Typography variant="body1" sx={{ fontFamily: 'Cairo, sans-serif', textAlign: 'right' }}>
                    {article.title}
                  </Typography>
                  <Typography variant="caption" sx={{ fontFamily: 'Cairo, sans-serif', textAlign: 'right', color: 'gray' }}>
                    {new Date(article.pubDate).toLocaleDateString()}
                  </Typography>
                </CardContent>
              </NewsCard>
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default GetHomeAds;
